<template>
  <main class="single">
    <div class="page-background">
      <section class="form">
        <h2>Account Info</h2>
        <div v-if="first_name" class="item">
          <span class="bold">{{ i18n.t("First Name:") }}</span>
          {{ first_name }}
        </div>
        <div v-if="last_name" class="item">
          <span class="bold">{{ i18n.t("Last Name:") }}</span>
          {{ last_name }}
        </div>
        <div v-if="userEmail" class="item">
          <span class="bold">{{ i18n.t("Email:") }}</span>
          {{ userEmail }}
        </div>
        <div v-if="username" class="item">
          <span class="bold">{{ i18n.t("Username:") }}</span>
          {{ username }}
        </div>
        <div v-if="organization" class="item">
          <span class="bold">{{ i18n.t("Organization:") }}</span>
          {{ organization }}
        </div>
        <div v-if="phone" class="item">
          <span class="bold">{{ i18n.t("Phone Number:") }}</span>
          {{ phone }}
        </div>
        <div v-if="address" class="item">
          <span class="bold">{{ i18n.t("Address:") }}</span>
          {{ address }}
        </div>
        <div v-if="city" class="item">
          <span class="bold">{{ i18n.t("City:") }}</span>
          {{ city }}
        </div>
        <div v-if="state" class="item">
          <span class="bold">{{ i18n.t("State/Province:") }}</span>
          {{ state }}
        </div>
        <div v-if="country" class="item">
          <span class="bold">{{ i18n.t("Country:") }}</span>
          {{ country }}
        </div>
        <div v-if="zip" class="item">
          <span class="bold">{{ i18n.t("Zip/Postal Code:") }}</span>
          {{ zip }}
        </div>
        <p class="message">
          {{
            i18n.t(
              "If any of this information is wrong, please notify tieredaccess@tucows.com."
            )
          }}
        </p>
      </section>
    </div>
  </main>
</template>

<script>
/**
 * @class UserAccountInfo
 *
 * Display TACO user account info
 */

import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userDetails: "user/userDetails",
      userEmail: "user/userEmail",
    }),

    /**
     * User first name
     * @return {string} first name
     */
    first_name() {
      return this.userDetails.first_name;
    },
    /**
     * User last name
     * @return {string} last name
     */
    last_name() {
      return this.userDetails.last_name;
    },
    /**
     * User's username
     * @return {string} username
     */
    username() {
      return this.userDetails.name;
    },
    /**
     * User's organization
     * @return {string} organization
     */
    organization() {
      return this.userDetails.organization;
    },
    /**
     * User's phone number
     * @return {string} phone number
     */
    phone() {
      return this.userDetails.phone_number;
    },
    /**
     * User's address
     * @return {string} address
     */
    address() {
      return this.userDetails.address1;
    },
    /**
     * User's city
     * @return {string} city
     */
    city() {
      return this.userDetails.city;
    },
    /**
     * User's state/province
     * @return {string} state
     */
    state() {
      return this.userDetails.state_province;
    },
    /**
     * User's country
     * @return {string} country
     */
    country() {
      return this.userDetails.country;
    },
    /**
     * User's zip/postal code
     * @return {string} zip
     */
    zip() {
      return this.userDetails.postal_code;
    },
  },
};
</script>

<style lang="less" scoped>
@import (reference) "theme";
.message {
  padding: 0 1em;
  font-size: 0.8rem;
  font-weight: normal;
}
.page-background {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 90vh;
  background: @grey1_lighter;
}
.item {
  padding-bottom: 1rem;
}
</style>
