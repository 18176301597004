var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single" }, [
    _c("div", { staticClass: "page-background" }, [
      _c("section", { staticClass: "form" }, [
        _c("h2", [_vm._v("Account Info")]),
        _vm._v(" "),
        _vm.first_name
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("First Name:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.first_name) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.last_name
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Last Name:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.last_name) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.userEmail
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Email:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.userEmail) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.username
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Username:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.username) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.organization
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Organization:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.organization) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.phone
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Phone Number:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.phone) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.address
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Address:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.address) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.city
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("City:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.city) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.state
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("State/Province:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.state) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.country
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Country:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.country) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.zip
          ? _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.i18n.t("Zip/Postal Code:"))),
              ]),
              _vm._v("\n        " + _vm._s(_vm.zip) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "message" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.i18n.t(
                  "If any of this information is wrong, please notify tieredaccess@tucows.com."
                )
              ) +
              "\n      "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }